<template>
  <common>
    <template v-slot:card-1>
      <v-card>
        <v-card-title> About </v-card-title>

        <v-card-text>
          <p>
            The team at
            <a href="https://drivendata.co" target="_blank">DrivenData</a> built
            this badge and service. Since we develop and maintain a number of
            <a href="https://www.drivendata.co/open-source.html" target="_blank"
              >open source projects</a
            >
            , we wanted a way to encourage building inclusive open source
            communities.
          </p>
          <p>
            Our goal was to make the open source community feel more welcoming
            to new contributors. Often it's not clear how to get in touch with a
            developer or to start contributing.
          </p>
          <p>
            Likewise, there are many developers and maintainers who want to
            share their knowledge and grow the contributor community.
          </p>
          <p>
            We hope that the mentor badge provides an easy way to connect new
            members of the open source community with the maintainers who want
            to support community around an open source project, a particular
            language, some technology stack, or open source software development
            more generally.
          </p>
          <p>
            Let us know if you like it on
            <a href="https://twitter.com/drivendataorg" target="_blank"
              >Twitter</a
            >,
            <a href="https://www.linkedin.com/company/9202422/" target="_blank"
              >LinkedIn</a
            >, or
            <a href="https://www.drivendata.co/" target="_blank"
              >through our site</a
            >!
          </p>
        </v-card-text>
      </v-card>
    </template>
  </common>
</template>

<script>
import Common from "../layout/Common.vue";

export default {
  name: "About",
  components: { Common },
  data() {
    return {};
  },
  methods: {
    mailto_dd: function () {
      window.open("mailto:mentor@drivendata.org");
    },
  },
};
</script>

<style scoped>
li ul li {
  padding-bottom: 0.2em;
}
</style>
