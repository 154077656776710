<template>
  <v-container>
    <v-row v-if="badge_url">
      <a :href="link_back" target="_blank">
        <img :src="badge_url" />
      </a>
    </v-row>
    <v-row v-if="badge_url">
      <small
        >Click to open a new window and see what potential mentees will
        see!</small
      >
    </v-row>
    <v-row>
      <v-col cols="12" md="8">
        <v-form v-model="valid">
          <v-text-field
            v-model="group"
            :counter="50"
            label="Who you mentor"
            required
          ></v-text-field>
          <v-text-field
            v-model="type"
            :counter="50"
            label="Kinds of projects"
            required
          ></v-text-field>
          <v-text-field
            v-model="contact_link"
            label="Contact link"
            required
          ></v-text-field>
          <v-btn
            x-small
            color="secondary"
            :href="contact_link"
            :disabled="!contact_link"
            outlined
            target="_blank"
            id="test-contact"
            >Test contact link</v-btn
          >
          <v-textarea
            v-model="how_work"
            label="How to work with you"
            outlined
            autogrow
          >
          </v-textarea>
        </v-form>
      </v-col>
      <v-col cols="12" md="4">
        <v-row class="pad-button">
          <v-btn color="primary" block @click="generate()" outlined ripple
            >Create badge!</v-btn
          >
        </v-row>
        <v-row class="pad-button">
          <v-btn
            class="pad-button"
            block
            v-if="markdown"
            outlined
            ripple
            @click="copy_to_clipboard('#markdownArea', $event)"
          >
            <v-icon left>
              {{
                copied.markdownArea
                  ? "mdi-clipboard-check"
                  : "mdi-clipboard-outline"
              }} </v-icon
            >Copy markdown</v-btn
          >
        </v-row>
        <v-row class="pad-button">
          <v-btn
            block
            v-if="html"
            outlined
            ripple
            class="pad-button"
            text-align="left"
            @click="copy_to_clipboard('#htmlArea', $event)"
          >
            <v-icon left>
              {{
                copied.htmlArea
                  ? "mdi-clipboard-check"
                  : "mdi-clipboard-outline"
              }} </v-icon
            >Copy html</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="badge_url">
        <textarea readonly :value="markdown" id="markdownArea"> </textarea>

        <textarea readonly :value="html" id="htmlArea"> </textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";

export default {
  data() {
    return {
      valid: true,
      group: "",
      type: "",
      how_work: "",
      badge_url: "",
      html: null,
      markdown: null,
      link_back: "",
      contact_link: "",
      copied: {
        markdownArea: false,
        htmlArea: false,
      },
    };
  },
  computed: {
    badge_root: function () {
      return process.env.VUE_APP_BADGE_API_URL;
    },
  },
  methods: {
    generate: function () {
      this.badge_url =
        this.badge_root +
        "?" +
        new URLSearchParams({
          group: this.group,
          project: this.type,
        });

      const _k = this.group + this.type + this.how_work;
      const c = new SimpleCrypto(_k);

      this.link_back =
        window.location.origin +
        "/mentee?" +
        new URLSearchParams({
          group: this.group,
          type: this.type,
          how_work: this.how_work,
          contact_link: c.encrypt(this.contact_link),
        });

      this.markdown = `[![mentor.build badge](${this.badge_url})](${this.link_back})`;
      this.html = `<a href="${this.link_back}"><img alt="mentor.build badge" src="${this.badge_url}"/></a>`;

      this.copied.markdownArea = false;
      this.copied.htmlArea = false;
    },
    copy_to_clipboard: function (id) {
      var el = document.querySelector(id);
      el.focus();
      el.select();

      var success = document.execCommand("copy");

      if (success) {
        this.copied[id.slice(1)] = true;
      } else {
        alert("Unable to copy automatically. Copy this text:\n\n" + el.value);
      }
    },
  },
};
</script>

<style>
#test-contact {
  margin-bottom: 1em !important;
}

.pad-button {
  padding-bottom: 2em;
}

#markdownArea,
#markdownArea::before,
#markdownArea::after,
#htmlArea,
#htmlArea::before,
#htmlArea::after {
  text-decoration: none !important;
  height: 0;
  position: absolute;
  z-index: -1;
}
</style>
