<template>
  <common>
    <template v-slot:card-1>
      <v-card>
        <v-card-title> Mentoring for Open Source</v-card-title>

        <v-card-text>
          <img
            alt="mentor.build badge"
            :src="badge_root + '?group=Anyone&project=Any+project'"
            style="padding: 1.5rem 0rem"
          />

          <p>
            Like other badges, the mentor badge reflects the values of your
            project.
          </p>

          <p>
            Open source software thrives with its community. As the size and
            diversity of the contributor community grows, so does the impact of
            open source.
            <strong
              >We believe one of the best ways to encourage inclusive growth is
              through building 1:1 relationships.</strong
            >
          </p>

          <p>
            The goal of <a href="//mentor.build">mentor.build</a> is to provide
            a visible indicator for developers of a particular project
            interested in mentoring new open source contributors. Use it to show
            you want to build a diverse open source community!
          </p>

          <p>
            Add this to your
            <a
              href="https://docs.github.com/en/free-pro-team@latest/github/setting-up-and-managing-your-github-profile/managing-your-profile-readme"
              target="_blank"
              >personal GitHub README</a
            >, your company’s open source page, or the README for a project that
            you maintain. You’ll be signing up to respond to incoming requests
            for mentorship.
          </p>

          <p>
            We’ll send potential mentees your way after they confirm their
            background and interests align with what you’ve specified.
          </p>
        </v-card-text>
      </v-card>
    </template>
    <template v-slot:card-2>
      <v-card>
        <v-card-title> What to consider </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" lg="4">
                <v-chip> Who you mentor </v-chip>
              </v-col>
              <v-col cols="12" lg="8">
                Want to support anyone in particular in your mentorship? Maybe
                you particularly want to encourage women, BIPOC, or LGBTQIA+
                developers. Maybe you would mentor anyone. It's up to you. We'll
                ask potential mentees to confirm they would be a match before
                giving out your contact link.
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="4">
                <v-chip> Kinds of projects </v-chip>
              </v-col>
              <v-col cols="12" lg="8">
                Let's be honest, not everyone would be a good mentor for every
                type of project. What works for you? Some options are:
                <ul>
                  <li>Just the name of your open source project</li>
                  <li>Only projects in a specific language</li>
                  <li>Only projects that use a certain technology</li>
                  <li>Any open source project</li>
                </ul>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="4">
                <v-chip> Contact link </v-chip>
              </v-col>
              <v-col cols="12" lg="8">
                Once a mentee confirms they are a good match, they'll be able to
                click through to contact you or your project. This link could be
                anything. Some options are:
                <ul>
                  <li>a <code>mailto:</code> link</li>
                  <li>
                    a link to a specific channel in Slack, discord, or gitter
                  </li>
                  <li>a contact form on your website</li>
                  <li>your twitter account</li>
                </ul>
                <small
                  >NOTE: We do some obfuscation so these links are not easily
                  scrapable from your repo.</small
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="4">
                <v-chip> How to work with you </v-chip>
              </v-col>
              <v-col cols="12" lg="8">
                How you work together is up to you. Maybe you can set aside 30
                minutes a week for a video call or pairing session. Maybe you'll
                respond to a few emails a week. Maybe you respond to messages in
                your project's chat channel. Whatever it is, you can communicate
                these boundaries with potential mentees so that everyone knows
                what to expect.
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </template>
    <template v-slot:card-3>
      <v-card>
        <v-card-title> Build your badge </v-card-title>
        <v-card-text>
          <badge-generator></badge-generator>
        </v-card-text>
      </v-card>
    </template>
  </common>
</template>

<script>
import BadgeGenerator from "../components/BadgeGenerator.vue";
import Common from "../layout/Common.vue";

export default {
  name: "Home",
  components: {
    BadgeGenerator,
    Common,
  },
  computed: {
    badge_root: function () {
      return process.env.VUE_APP_BADGE_API_URL;
    },
  },
};
</script>
