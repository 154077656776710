import Vue from "vue";
import Home from "./pages/Home.vue";
import Mentee from "./pages/Mentee.vue";
import CodeOfConduct from "./pages/CodeOfConduct.vue";
import About from "./pages/About.vue";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

const routes = {
  "/": Home,
  "/mentee": Mentee,
  "/conduct": CodeOfConduct,
  "/about": About,
};

new Vue({
  vuetify,
  data: {
    currentRoute: window.location.pathname,
  },
  computed: {
    ViewComponent() {
      return routes[this.currentRoute];
    },
  },
  render(h) {
    return h(this.ViewComponent);
  },
}).$mount("#app");
