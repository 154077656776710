<template>
  <common>
    <template v-slot:card-1>
      <v-card>
        <v-card-title> Code of Conduct </v-card-title>

        <v-card-text>
          <p>
            We ask that anyone who wants to participate in mentorship be
            respectful of the other members of the community and act in
            accordance with this Code of Conduct.
          </p>
          <ul class="simple">
            <li><strong>Be friendly and patient.</strong></li>

            <li>
              <strong>Respect people's time and boundaries.</strong>
              Time is a precious resource. Please, only engage in mentorship on
              either side if you intend to make a good-faith effort to either
              (1) as a mentor, help someone become a contributor to open source
              software (2) as a mentee, learn in a respectful way from a mentor
              how to contribute. Mentors are asked to lay out how best to work
              with them, so please take this into account. That said, not
              everyone always has time to mentor. If someone doesn't respond or
              can't engage, please respect that.
            </li>

            <li>
              <strong>Be welcoming.</strong> We strive to be a community that
              welcomes and supports people of all backgrounds and identities.
              This includes, but is not limited to members of any race,
              ethnicity, culture, national origin, colour, immigration status,
              social and economic class, educational level, sex, sexual
              orientation, gender identity and expression, age, size, family
              status, political belief, religion, and mental and physical
              ability.
            </li>

            <li>
              <strong>Be considerate.</strong> Your work will be used by other
              people, and you in turn will depend on the work of others. Any
              decision you take will affect users and colleagues, and you should
              take those consequences into account when making decisions.
              Remember that we're a world-wide community, so you might not be
              communicating in someone else's primary language.
            </li>

            <li>
              <strong>Be respectful.</strong> Not all of us will agree all the
              time, but disagreement is no excuse for poor behavior and poor
              manners. We might all experience some frustration now and then,
              but we cannot allow that frustration to turn into a personal
              attack. It’s important to remember that a community where people
              feel uncomfortable or threatened is not a productive one.
            </li>

            <li>
              <strong>Be careful in the words that you choose.</strong> We are a
              community of professionals, and we conduct ourselves
              professionally. Be kind to others. Do not insult or put down other
              participants. Harassment and other exclusionary behavior aren't
              acceptable. This includes, but is not limited to:
              <ul>
                <li>
                  Violent threats or language directed against another person.
                </li>
                <li>Discriminatory jokes and language.</li>
                <li>Posting sexually explicit or violent material.</li>
                <li>
                  Posting (or threatening to post) other people's personally
                  identifying information ("doxing").
                </li>
                <li>
                  Personal insults, especially those using racist or sexist
                  terms.
                </li>
                <li>Unwelcome sexual attention.</li>
                <li>
                  Advocating for, or encouraging, any of the above behavior.
                </li>
                <li>
                  Repeated harassment of others. In general, if someone asks you
                  to stop, then stop.
                </li>
              </ul>
            </li>
          </ul>

          <p>
            <strong>What to do if there are violations</strong>
          </p>
          <ul>
            <li>
              <strong>Mentors:</strong> If there are violations, you can remove
              the mentor.build badge from your project. If this happens, please
              <a @click="mailto_dd()">reach out and let us know what happened</a
              >.
            </li>
            <li>
              <strong>Mentees:</strong> If you
              <a @click="mailto_dd()">reach out to us</a> and let us know the
              project and what happened, we will get in touch with the
              maintainer and ask they not use the mentor.build badge.
            </li>
          </ul>

          Adapted from the
          <a href="https://www.djangoproject.com/conduct/"
            >Django Project Code of Conduct</a
          >; Original text courtesy of the
          <a
            href="http://web.archive.org/web/20141109123859/http://speakup.io/coc.html"
          >
            Speak Up! project</a
          >.
        </v-card-text>
      </v-card>
    </template>
  </common>
</template>

<script>
import Common from "../layout/Common.vue";

export default {
  name: "CodeOfConduct",
  components: { Common },
  data() {
    return {};
  },
  methods: {
    mailto_dd: function () {
      window.open("mailto:mentor@drivendata.org");
    },
  },
};
</script>

<style scoped>
li {
  padding-bottom: 2em;
}

li ul li {
  padding-bottom: 0.2em;
}
</style>
