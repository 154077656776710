import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#F0F',
            secondary: '#00c6ff',
            accent: '#FF0',
            error: '#b71c1c',
          },
        },
      },
});
