<template>
  <v-app>
    <div id="bg"></div>
    <v-container id="main-container" fluid style="max-width: 1600px">
      <v-row id="header">
        <v-col cols="12" class="d-flex">
          <v-btn icon href="/" title="mentor.build home">
            <v-icon>mdi-home</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn icon href="/conduct" title="Code of Conduct">
            <v-icon>mdi-playlist-check</v-icon>
          </v-btn>
          <v-btn icon href="/about" title="About mentor.build">
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row id="row-1">
        <v-col cols="12" lg="6">
          <v-container
            id="title-logo"
            align="center"
            text-align="center"
            justify="center"
            fill-height
          >
            <div>
              <h1>mentor.</h1>
              <h1>build.</h1>
            </div>
          </v-container>
        </v-col>
        <v-col cols="12" lg="6">
          <slot name="card-1"></slot>
        </v-col>
      </v-row>
      <v-row id="row-2" style="padding-bottom: 10rem">
        <v-col cols="12" lg="6">
          <slot name="card-2"></slot>
        </v-col>
        <v-col cols="12" lg="6">
          <slot name="card-3"></slot>
        </v-col>
      </v-row>
    </v-container>
    <v-card id="spark">
      <v-sparkline
        :fill="fill"
        :gradient="gradient"
        :padding="padding"
        :smooth="radius || false"
        :value="value"
        auto-draw
        height="100"
      ></v-sparkline>
    </v-card>

    <span style="position: absolute; bottom: 0; right: 0"
      >Photo by
      <a
        href="https://unsplash.com/@nesabymakers?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
        >NESA by Makers</a
      >
      on
      <a
        href="https://unsplash.com/s/photos/mentor?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
        >Unsplash</a
      ></span
    >
  </v-app>
</template>

<script>
export default {
  name: "Common",
  data() {
    return {
      fill: true,
      gradient: ["#00c6ff", "#F0F", "#FF0"],
      padding: 0,
      radius: 20,
      value: [0, 2, 1, 4, 2, 3, 4, 5, 6, 6, 4, 8, 7, 9, 10, 10],
    };
  },
};
</script>

<style lang="css">
@import url("https://fonts.googleapis.com/css2?family=Cabin+Sketch:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

.v-application {
  font-family: "Open Sans", sans-serif !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

#bg {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 198, 255, 0.5),
      rgba(255, 0, 255, 0.5),
      rgba(255, 255, 0, 0.5)
    ),
    url("../assets/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
}

#main-container {
  z-index: 200;
}

#title-logo {
  justify-content: center;
  font-family: "Cabin Sketch", cursive;
  color: rgba(255, 255, 0, 1);
  font-size: 5em !important;
}

@media screen and (max-width: 420px) {
  #title-logo {
    font-size: 3em !important;
  }
}

#spark {
  background-color: rgba(0, 0, 0, 0);
  border: 0 0 0 0;
  border-radius: 0 !important;
  padding: 0;
  margin: 0;
  width: 100% !important;
  box-shadow: 0 0 0;
  position: fixed;
  bottom: 0px;
}

.v-card {
  border-radius: 2em !important;
  margin: 0.25rem 1.5rem;
  padding: 3.25rem;
  color: rgba(0.1, 0.1, 0.1, 1) !important;
}

@media screen and (max-width: 420px) {
  .v-card {
    margin: 0;
    padding: 0;
  }
}

.v-card__title {
  font-family: "Cabin Sketch", cursive;
  font-size: 3em !important;
  font-weight: 700;
  color: rgba(0, 198, 255);
  word-break: normal !important;
}

@media screen and (max-width: 420px) {
  .v-card__title {
    font-size: 2em !important;
  }
}

header .v-toolbar__title {
  font-family: "Londrina Sketch", cursive;
  font-size: 2em !important;
  color: rgba(0, 198, 255);
}

p {
  line-height: 2rem;
  font-size: 1.2rem;
}
</style>
