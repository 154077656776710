<template>
  <common>
    <template v-slot:card-1>
      <v-card>
        <v-card-title>Hello!</v-card-title>
        <v-card-text>
          <p>
            We’re excited that you want to contribute to open source! You've
            been linked here by an organization or maintainer that supports
            building a diverse and inclusive community of open source
            contributors.
          </p>
          <p>
            They are willing to mentor
            <span class="text-decoration-underline">{{ group }}</span
            >, and they are happy to help with
            <span class="text-decoration-underline">{{ type }}</span
            >.
          </p>
          <p>
            Mentors are volunteers, so we ask that you read the
            <a href="/conduct" target="_blank">Code of Conduct</a> and be
            respectful of the mentors' time and boundaries.
          </p>
          <p v-if="how_work">Here are a few notes on how to work with them:</p>
          <v-alert v-if="how_work" border="left" color="secondary" text>{{
            how_work
          }}</v-alert>
        </v-card-text>
      </v-card>
    </template>

    <template v-slot:card-2>
      <v-card>
        <v-card-title> Are you a good fit? </v-card-title>
        <v-card-text>
          <p>
            Check these boxes to indicate your background is a good fit for the
            mentorship that is offered.
          </p>
          <v-checkbox v-model="check_group" color="primary" hide-details>
            <template v-slot:label>
              I'm part of the group that the maintiner(s) would like to mentor:
              {{ group }}
            </template>
          </v-checkbox>
          <v-checkbox v-model="check_type" color="secondary" hide-details>
            <template v-slot:label>
              I'd like mentorship contributing to open source projects of the
              type: {{ type }}
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="check_code"
            color="rgb(255, 165, 90)"
            hide-details
          >
            <template v-slot:label>
              I agree to act in accordance with the Code of Conduct.
            </template>
          </v-checkbox>
          <v-btn
            outlined
            x-small
            href="/conduct"
            style="margin-top: 2em"
            target="_blank"
            >Read the Code of Conduct</v-btn
          >
        </v-card-text>
      </v-card>
    </template>

    <template v-slot:card-3>
      <v-card v-if="all_checked" transition="scale-transition">
        <v-card-title> Great! </v-card-title>
        <v-card-text>
          <p>
            Let them know why you're reaching out with a message like the
            following:
          </p>

          <p>
            <em>
              Hi! I am reaching out through mentor.build. I saw your mentor
              badge, and I would love some help working on open source. My goal
              is to...
            </em>
          </p>
          <v-btn
            v-if="all_checked"
            color="primary"
            @click="click_contact"
            outlined
            ripple
          >
            Put me in touch!
          </v-btn>
        </v-card-text>
      </v-card>
    </template>
  </common>
</template>

<script>
import Common from "../layout/Common.vue";
import SimpleCrypto from "simple-crypto-js";

export default {
  name: "Mentee",
  components: { Common },
  data() {
    return {
      group: "",
      type: "",
      how_work: "",
      contact_link_e: "",
      check_group: false,
      check_type: false,
      check_code: false,
    };
  },
  computed: {
    all_checked: function () {
      return this.check_group && this.check_type && this.check_code;
    },
  },
  methods: {
    click_contact: function () {
      const _k = this.group + this.type + this.how_work;
      const c = new SimpleCrypto(_k);

      var contact_link = c.decrypt(this.contact_link_e);
      window.open(contact_link, "_blank");
    },
  },
  created: function () {
    let uri = window.location.search;
    let params = new URLSearchParams(uri);

    this.group = params.get("group");
    this.type = params.get("type");
    this.how_work = params.get("how_work");
    this.contact_link_e = params.get("contact_link");
  },
};
</script>
